import "./Commissions.css";
import { Layout } from 'antd';
import { Breadcrumb , Row , Col , Table } from "react-bootstrap";
import Sidebar from "../../components/Layout/Sidebar/Sidebar";
import Header from "../../components/Layout/Header/Header";
import download from "../../assets/image/download.svg";
import edit from "../../assets/image/edit.svg";
import email from "../../assets/image/email.svg";

const Commissions = () =>{
    return(
        <>
            <Sidebar />
            <Layout>
                <Header />
                <div className="main_content">
                    <div className="page_titles">
                        <Breadcrumb>
                            <Breadcrumb.Item href="/">Home</Breadcrumb.Item>
                            <Breadcrumb.Item active>Commissions</Breadcrumb.Item>
                        </Breadcrumb>
                        <h3>Commissions</h3>
                    </div>
                    {/* <Row>
                        <Col xl={4} lg={6} >
                            <div className="clients_list">
                                <div className="card">
                                    <h5>Paid</h5>
                                    <p>2 Commissions</p>
                                    <button className="orange_btn" >Select</button>
                                </div>
                            </div>
                        </Col>
                        <Col xl={4} lg={6} >
                            <div className="clients_list">
                                <div className="card">
                                    <h5>Outstanding</h5>
                                    <p>6 Commissions</p>
                                    <button className="orange_btn" >Select</button>
                                </div>
                            </div>
                        </Col>
                    </Row> */}
                    {/* 10 Nov Change Start  */}
                    <div className="outstanding_main">
                        <h5>Outstanding</h5>
                        <div className="report_table">
                            <Table>
                                <thead>
                                    <tr>
                                        <th>Client</th>
                                        <th>Address</th>
                                        <th>Report type</th>
                                        <th>Referrer</th>
                                        <th>Referance</th>
                                        <th>Order date</th>
                                        <th>Enquiry</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td><b>Brad Hibbard</b></td>
                                        <td>34A/11-21 Underwood Road Homebush NSW 2140</td>
                                        <td>Tax Depreciation Schedule</td>
                                        <td>Brad Hibbard</td>
                                        <td>123456</td>
                                        <td>12/05/2022</td>
                                        <td>
                                            <img src={download} alt="download" />
                                            <img src={email} alt="email" />
                                            <img src={edit} alt="edit" />
                                        </td>
                                    </tr>
                                    <tr>
                                        <td><b>Brad Hibbard</b></td>
                                        <td>34A/11-21 Underwood Road Homebush NSW 2140</td>
                                        <td>Tax Depreciation Schedule</td>
                                        <td>Brad Hibbard</td>
                                        <td>123456</td>
                                        <td>12/05/2022</td>
                                        <td>
                                            <img src={download} alt="download" />
                                            <img src={email} alt="email" />
                                            <img src={edit} alt="edit" />
                                        </td>
                                    </tr>
                                    <tr>
                                        <td><b>Brad Hibbard</b></td>
                                        <td>34A/11-21 Underwood Road Homebush NSW 2140</td>
                                        <td>Tax Depreciation Schedule</td>
                                        <td>Brad Hibbard</td>
                                        <td>123456</td>
                                        <td>12/05/2022</td>
                                        <td>
                                            <img src={download} alt="download" />
                                            <img src={email} alt="email" />
                                            <img src={edit} alt="edit" />
                                        </td>
                                    </tr>
                                    <tr>
                                        <td><b>Brad Hibbard</b></td>
                                        <td>34A/11-21 Underwood Road Homebush NSW 2140</td>
                                        <td>Tax Depreciation Schedule</td>
                                        <td>Brad Hibbard</td>
                                        <td>123456</td>
                                        <td>12/05/2022</td>
                                        <td>
                                            <img src={download} alt="download" />
                                            <img src={email} alt="email" />
                                            <img src={edit} alt="edit" />
                                        </td>
                                    </tr>
                                </tbody>
                            </Table>
                        </div>
                    </div>
                    <div className="outstanding_main">
                        <h5>Paid</h5>
                        <div className="report_table">
                            <Table>
                                <thead>
                                    <tr>
                                        <th>Client</th>
                                        <th>Address</th>
                                        <th>Report type</th>
                                        <th>Referrer</th>
                                        <th>Referance</th>
                                        <th>Order date</th>
                                        <th>Enquiry</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td><b>Brad Hibbard</b></td>
                                        <td>34A/11-21 Underwood Road Homebush NSW 2140</td>
                                        <td>Tax Depreciation Schedule</td>
                                        <td>Brad Hibbard</td>
                                        <td>123456</td>
                                        <td>12/05/2022</td>
                                        <td>
                                            <img src={download} alt="download" />
                                            <img src={email} alt="email" />
                                            <img src={edit} alt="edit" />
                                        </td>
                                    </tr>
                                    <tr>
                                        <td><b>Brad Hibbard</b></td>
                                        <td>34A/11-21 Underwood Road Homebush NSW 2140</td>
                                        <td>Tax Depreciation Schedule</td>
                                        <td>Brad Hibbard</td>
                                        <td>123456</td>
                                        <td>12/05/2022</td>
                                        <td>
                                            <img src={download} alt="download" />
                                            <img src={email} alt="email" />
                                            <img src={edit} alt="edit" />
                                        </td>
                                    </tr>
                                    <tr>
                                        <td><b>Brad Hibbard</b></td>
                                        <td>34A/11-21 Underwood Road Homebush NSW 2140</td>
                                        <td>Tax Depreciation Schedule</td>
                                        <td>Brad Hibbard</td>
                                        <td>123456</td>
                                        <td>12/05/2022</td>
                                        <td>
                                            <img src={download} alt="download" />
                                            <img src={email} alt="email" />
                                            <img src={edit} alt="edit" />
                                        </td>
                                    </tr>
                                    <tr>
                                        <td><b>Brad Hibbard</b></td>
                                        <td>34A/11-21 Underwood Road Homebush NSW 2140</td>
                                        <td>Tax Depreciation Schedule</td>
                                        <td>Brad Hibbard</td>
                                        <td>123456</td>
                                        <td>12/05/2022</td>
                                        <td>
                                            <img src={download} alt="download" />
                                            <img src={email} alt="email" />
                                            <img src={edit} alt="edit" />
                                        </td>
                                    </tr>
                                </tbody>
                            </Table>
                        </div>
                    </div>
                    {/* 10 Nov Change End  */}
                </div>
            </Layout>
        </>
    );
}

export default Commissions;