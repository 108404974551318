import "./Sidebar.css";
import { Layout, Menu } from 'antd';
import { Link } from "react-router-dom";
import logo from "../../../assets/image/logo.svg";
const { Sider } = Layout;

const Sidebar = () =>{
    return(
        <>
            <Sider
                breakpoint="xl"
                collapsedWidth="0"
                onBreakpoint={broken => {
                    console.log(broken);
                }}
                onCollapse={(collapsed, type) => {
                    console.log(collapsed, type);
                }}
                className="sidebar_main"
            >
                <img src={logo} alt="logo" className="sidebar_logo" />
                <Menu mode="inline" >
                    {/* <Menu.Item key="1" ><Link to="/startform" >Refer a client</Link></Menu.Item> */}
                    <Menu.Item key="2" ><Link to="/" >Home</Link></Menu.Item>
                    <Menu.Item key="3" ><Link to="/clients" >My clients</Link></Menu.Item>
                    <Menu.Item key="4" ><Link to="/reports" >Reports & schedules</Link></Menu.Item>
                    <Menu.Item key="5" ><Link to="/commissions" >Commissions</Link></Menu.Item>
                    <Menu.Item key="6" ><Link to="/bankdetails" >Update bank details</Link></Menu.Item>
                    <Menu.Item key="7" ><Link to="/resources" >Resources</Link></Menu.Item>
                    <Menu.Item key="8" ><Link to="/contact" >Contact andy</Link></Menu.Item>
                    <Menu.Item key="9" ><Link to="/updatedetails" >Update my details</Link></Menu.Item>
                    <Menu.Item key="10" ><Link to="/adduser" >Add user</Link></Menu.Item>
                    {/* 10 Nov Change Start */}
                    <Menu.Item key="11" ><Link to="/login" >Log out</Link></Menu.Item>
                    {/* 10 Nov Change End */}
                </Menu>
            </Sider>
        </>
    );
}

export default Sidebar;